import React from "react";
import { MdOutlineSearch } from "react-icons/md";
import "./SearchAndCategory.css";

const SearchAndCategory = ({ serach, setSearch }) => {
  return (
    <div className="SearchAndCategory">
      <h1>Explore InfluPlanet</h1>
      <p className="explore-desc">
      Select the Influencer you´d like to chat with. All celebs listed here have been crafted with their authentic collaboration to ensure a top-tier experience
      </p>
      <div className="search_input_div">
        <MdOutlineSearch />
        <input
          type="text"
          placeholder="Explore InfluPlanet"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      {/* ---------category */}
      {/* <div className="category">
        <li>Location</li>
        <li>Number of Followers </li>
        <li>Active platforms</li>
        <li>Type of content</li>
      </div> */}
    </div>
  );
};

export default SearchAndCategory;
