import React from "react";
import Footer1 from "../Footer1/Footer1";
import "./Footer.css";
import {
  AiOutlineTwitter,
  AiOutlineInstagram,
} from "react-icons/ai";
import { BiLogoDiscordAlt} from "react-icons/bi";


const Footer = () => {
  return (
    <div className="footer">
      <Footer1 show={true} />
      {/* ----------- bottom_footer */}
      <div className="bottom_footer">
        <div className="footer_icons">
          <a href="https://twitter.com/Influchats"><AiOutlineTwitter/></a>
          
          <a href="https://www.instagram.com/influchats/"><AiOutlineInstagram/></a>
          
          <a href="https://discord.gg/AYaTZHbwkD"><BiLogoDiscordAlt /></a>
          
        </div>
        <p>© 2023 Influchats, LLC. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
