import React from "react";
import "./Footer1.css";
import { WPSITE } from "../../../globalLinks";

const Footer1 = () => {

  return (
    <div className="footer1">
      {/* --------- left header  */}
      <div className="left_footer">
      <a href={`${WPSITE}`}>
        <img src="./Assets/Group-3.svg" alt="" />
        </a>
      </div>
      {/* ----------- right header  */}
      <div
        className="right_footer"
      >
        <ul>
          
          <li>
            <a href={`${WPSITE}/privacy/`}>Privacy</a>
          </li>
          <li>
          <a href={`${WPSITE}/terms`}>Terms</a>
            {/* <a href="home">Influcons</a> */}
          </li>
          <li>
            <a href={`${WPSITE}/#pricing`}>Pricing</a>
            {/* <a href="home">Pricing</a> */}
          </li>
          <li>
            <a href={`${WPSITE}faqs/`}>FAQ</a>
            {/* <a href="home">Roadmap</a> */}
          </li>
          <li>
            <a href={`${WPSITE}/contact-us/`}>Contact us</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer1;
