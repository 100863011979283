import React, { useEffect, useState } from "react";
import Header from "../Components/Layout/Header/Header";
import "../Components/Home/Style/Home.css";
import SearchAndCategory from "../Components/Layout/SearchAndCategory/SearchAndCategory";
import Card from "../Components/Card/Card";
import Join from "../Components/Layout/Join-Section/Join";
import Footer from "../Components/Layout/Footer/Footer";
import { useSelector } from "react-redux";

const Home = () => {
  const products = useSelector((state) => state.product.products);
  const [count, setCount] = useState(1);
  const [newData, setNewData] = useState(products && products);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (search === "") {
      setNewData(products && products.slice(0, 8));
    } else {
      const filter = products.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      );
      setNewData(filter);
    }
  }, [search]);

  return (
    <div>
    <div className="home">
      <Header />
      <SearchAndCategory search={search} setSearch={setSearch} />
      <Card
        data={products && products}
        count={count}
        setCount={setCount}
        newData={newData}
        setNewData={setNewData}
      />
      <Join />
    </div>
    <div className="footer-home"><Footer /></div>
    </div>
    
  );
};

export default Home;
