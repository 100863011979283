import React, { useEffect, useState } from "react";
import { AiOutlineInstagram, AiOutlineTwitter, AiOutlineYoutube } from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";
import "./style/Card.css";
import { useSelector } from "react-redux";


const Card = ({ data, count, setCount, newData, setNewData }) => {
  const isloading = useSelector((state) => state.product.isloading);

  // Helper function to format follower count
  const formatFollowerCount = (count) => {
    if (count < 1000) {
      return count.toString();
    } else if (count < 1000000) {
      return (count / 1000).toFixed(1) + "K";
    } else if (count < 1000000000) {
      return (count / 1000000).toFixed(1) + "M";
    } else {
      return (count / 1000000000).toFixed(1) + "B";
    }
  };


  useEffect(() => {
    const newCount = count * 8;
    setNewData(data && data.slice(0, newCount));
  }, [count, data]);

  return (
    <>
      {isloading ? (
        <p
          style={{
            fontSize: "23px",
            textAlign: "center",
            margin: "20px 0px",
            color: "white",
          }}
        >
          Loading...
        </p>
      ) : (
        <div className="card_parent">
          <div className="main_card">
            <div className="main_liner_color"></div>
            {newData &&
              newData.map((item, index) => {
                return (
                  <div className="card_box" key={index}>
                    <div className="liner_color"></div>
                    <img src={item.profilePic && item.profilePic} alt="" />
                    <h2 className="card_title">{item.name}</h2>
                    <p className="card_category">{item.cate}</p>

                    <p className="tik_insta">{item.category}</p>
                    {/* <p className="follower">
                      {formatFollowerCount(item.followers)} Followers
                    </p> */}
                    <p>
                    </p>
                    <div className="icons">
                      {/* Link to the user's Instagram profile */}
                      {item.social_media.instagram && (<a
                        href={item.social_media.instagram}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <AiOutlineInstagram />
                      </a>
                      )}
                      {/* Link to the user's TikTok profile */}
                      {item.social_media.tiktok && (
                        <a
                          href={item.social_media.tiktok}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FaTiktok />
                        </a>
                      )}
                      {/* Link to the user's Twitter profile */}
                      {item.social_media.twitter && (<a
                        href={item.social_media.twitter}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <AiOutlineTwitter />
                      </a>
                      )}
                      {item.social_media.youtube && (
                        <a
                        href={item.social_media.youtube}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <AiOutlineYoutube />
                      </a>
                      )}
                      

                    </div>
                    <button>Chat Now</button>
                  </div>
                );
              })}
          </div>
          {/* ----  */}
          <button className="LoadMore" onClick={() => setCount(count + 1)}>
            Load More
          </button>
        </div>
      )}
    </>
  );
};

export default Card;
