import { GET_PRODUCTS_REQUEST, GET_PRODUCTS_REQUEST_FAIL, GET_PRODUCT_ERROR, GET_PRODUCT_SUCCESS } from "../Variables/Product"



export const GetAllProductFunc = () => async (dispatch) => {
    try {
        dispatch({ type: GET_PRODUCTS_REQUEST })

        // Use the CORS proxy URL before your target API URL
        const proxyUrl = 'https://k79mchvn17.execute-api.eu-central-1.amazonaws.com/'; // Update with your proxy URL
        const apiUrl = 'https://influchats-public-data.s3.eu-central-1.amazonaws.com/explore.json';

        const res = await fetch(apiUrl, {
            method: "GET",
        });

        dispatch({ type: GET_PRODUCTS_REQUEST_FAIL })
        const data = await res.json()
        data.sort((a,b)=>  b.followers - a.followers)
        dispatch({ type: GET_PRODUCT_SUCCESS, payload: data })

    } catch (error) {
        console.log(error);
        dispatch({ type: GET_PRODUCT_ERROR })
    }
}