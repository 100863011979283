import { createReducer } from "@reduxjs/toolkit";
import { GET_PRODUCTS_REQUEST, GET_PRODUCTS_REQUEST_FAIL, GET_PRODUCT_ERROR, GET_PRODUCT_SUCCESS } from "../Variables/Product";

const initailState = {
    isloading: false,
    products: []
}

export const ProductReducer = createReducer(initailState, (builder) => {
    builder
        .addCase(GET_PRODUCTS_REQUEST, (state) => {
            state.isloading = true
        })
        .addCase(GET_PRODUCTS_REQUEST_FAIL, (state) => {
            state.isloading = false
        })
        .addCase(GET_PRODUCT_SUCCESS, (state, action) => {
            state.isloading = false;
            state.products = action.payload
        })
        .addCase(GET_PRODUCT_ERROR, (state) => {
            state.isloading = false
        })
})