import React from "react";
import "./Join.css";

const Join = () => {
  
  const clickFunc = async () => {
    let data =
    {
      "email": document.getElementById('WailistEmailInput').value
    };
    console.log(data);
    
    try {
      const response = await fetch('https://api.influchats.com/waitlist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'  // <---- Set the content type to application/json
        },
        body: JSON.stringify(data)   // <---- Convert the data object to a JSON string
      });
      // const response = fetch('https://api.influchats.com/waitlist');
      console.log(response);

    } catch (error) {
      // console.error;
      console.error(error);
    }

  };

  // useEffect(() => {

  //   const fetchData = async () => {

      
  //   }
  //   fetchData();
  // }, [data])


  return (
    <div className="JoinWrapper" >
      <div className="upperJoin"></div>
      <div className="join">
        <h2>Join the Exclusive Waitlist</h2>
        <p>
          Get early access to our innovative fan interaction platform. Join the Whitelist to chat and get photos on demand from AI-powered clones of your favorite influencers Don’t miss this unique chance to connect like never before. Reserve your spot today!
        </p>
        <div className="join_email">
          <input type="text" id="WailistEmailInput" placeholder="Enter Email" />
          <button onClick={clickFunc}>Join</button>
        </div>
      </div>
    </div>
  );
};

export default Join;
