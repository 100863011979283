import React, { useState } from "react";
import "./Header.css";
import { RxCross1 } from "react-icons/rx";
import { AiOutlineMenu } from "react-icons/ai";
import { WPSITE } from "../../../globalLinks";

const Header = ({ show = false }) => {
  const [showmenu, SetShowmenu] = useState(false);

  return (
    <div className="header">
      {/* --------- left header  */}
      <div className="left_header">
        <a href={`${WPSITE}`}>
        <img src="./Assets/Group-3.svg" alt="" />
        </a>
      </div>
      {!show && (
        <AiOutlineMenu className="burger" onClick={() => SetShowmenu(true)} />
      )}
      {/* ----------- right header  */}
      <div
        className={
          showmenu && !show ? "show_sidebar right_header" : "right_header"
        }
      >
        <RxCross1 className="cross" onClick={() => SetShowmenu(false)} />
        <ul>
          <li className="active-link">
          InfluPlanet
          {/* <a href=""></a> */}
            {/* <a href="home">Influcons</a> */}
          </li>
          <li>
            <a href={`${WPSITE}/#pricing`}>Pricing</a>
            {/* <a href="home">Pricing</a> */}
          </li>
          <li>
            <a href={`${WPSITE}/roadmap/`}>Roadmap</a>
            {/* <a href="home">Roadmap</a> */}
          </li>
          {/* <button className="btn">Get Started</button> */}
        </ul>
        {/* <a href={`${WPSITE}`}><button>Get Started</button></a> */}
      </div>
    </div>
  );
};

export default Header;
