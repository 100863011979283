import React, { useEffect } from 'react'
import Home from './Pages/Home'
import { useDispatch } from 'react-redux'
import { GetAllProductFunc } from './Components/redux/Action/Product'

const App = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(GetAllProductFunc())
  }, [])
  return (
    <>
      <Home />
    </>
  )
}

export default App